<template>
  <div>
    <page-component
      pageTitle="Count History"
      :headers="headers"
      :items="counts"
      sortDesc
      :buttons="[{ text: '+ Count Inventory', to: '/count/AddEditForm' }]"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ getCountDate(item.date) }}
        <br />
        <span style="color: var(--v-lighttext-base)">
          {{ getCountTime(item.date) }}
        </span>
      </template>
      <template v-slot:[`item.userId`]="{ item }">
        {{ getUserDisplayName(item.userId) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="eye" @click="viewCount(item)" />
      </template>
    </page-component>
    <review
      v-if="selectedCountId"
      historyView
      :date="getDateLabel(selectedCount.date)"
      :countItems="selectedCount.countItems"
      :inventoryItems="items"
      :comments="selectedCount.comment"
    />
  </div>
</template>

<script>
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import Review from "./Review"
import { mapState, mapGetters } from "vuex"
import moment from "moment"

export default {
  name: "count-history",
  components: { PageComponent, Review },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Date", value: "date" },
        { text: "User", value: "userId" },
        { text: "Actions", value: "actions" },
      ],
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState(["restaurants", "firebaseRefs", "users", "counts", "items"]),
    selectedCountId() {
      return this.$route.params.id
    },
    selectedCount() {
      return this.counts.find(count => count.id === this.selectedCountId)
    },
  },
  methods: {
    getUserDisplayName(userId) {
      if (this.users) {
        const userDoc = Object.values(this.users)?.find(u => u.userId === userId)
        if (userDoc?.name) return userDoc.name
      }
      return "N/A"
    },
    getCountDate(date) {
      return moment(date.toDate()).format("MMM DD, YYYY")
    },
    getCountTime(date) {
      return moment(date.toDate()).format("h:mm A")
    },
    getDateLabel(date) {
      return moment(date.toDate()).format("YYYY-MM-DD HH:mm:ss")
    },
    viewCount(count) {
      this.$router.push({ params: { form: "Review", id: count.id } })
    },
  },
}
</script>
