<template>
  <div
    class="d-flex flex-grow-1 align-center justify-space-around field-wrapper"
    :class="{ scrollable: scrollable }"
  >
    <div
      v-if="loading || !displayLoaded"
      class="d-flex flex-grow-1 justify-center align-center"
    >
      <span style="min-height: 55px" class="pt-4 mr-n3">
        <v-progress-circular
          indeterminate
          color="lighttext"
          size="24"
          width="1"
        ></v-progress-circular>
      </span>
    </div>
    <scroll-picker
      v-else-if="scrollable"
      v-model="displayQuantity"
      class="d-flex flex-shrink-1 scrollable-field"
      :class="{ disabled: inputOptions.length === 1 }"
      :options="inputOptions"
      :disabled="disabled"
      @input="handleChange"
    />
    <v-text-field
      v-else
      v-model="displayQuantity"
      class="d-flex flex-grow-1 non-scrollable-field"
      type="number"
      min="0"
      solo
      background-color="transparent"
      flat
      dense
      hide-details
      @input="handleChange"
    />
    <div v-if="showUnits" class="d-flex flex-grow-1 my-n2">
      <v-select
        v-if="unitsList && unitsList.length"
        v-model="displayUnitId"
        class="unit-select mx-n2"
        style="min-width: 7em"
        :items="unitsList"
        item-text="name"
        item-value="id"
        flat
        solo
        dense
        hide-details
        background-color="transparent"
        label="Unit"
        @input="handleChange"
      ></v-select>
      <span v-else class="ml-n1">{{ suffix }}</span>
    </div>
  </div>
</template>

<script>
import { ScrollPicker } from "vue-scroll-picker"
import "vue-scroll-picker/dist/style.css"
import topLevelMixins from "../../mixins.js"
import { mapState } from "vuex"

export default {
  name: "inventory-quantity-field",
  components: {
    ScrollPicker,
  },
  mixins: [topLevelMixins],
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        unit: "",
        quantity: 0,
        preferredDisplayUnitId: "",
      }),
    },
    item: {
      type: Object,
      required: true,
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    unitsList: {
      type: Array,
      required: false,
      default: () => [],
    },
    suffix: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      displayLoaded: false,
      displayUnit: this.value.unit,
      displayQuantity: this.value.quantity,
      displayUnitId: this.value.preferredDisplayUnitId,
    }
  },
  computed: {
    ...mapState(["units"]),
    showUnits() {
      return (this.unitsList && this.unitsList.length > 0) || this.suffix !== ""
    },
    inputOptions() {
      // an array from 1 to 999
      const ops = Array.from({ length: 999 }, (_, i) => i + 1)
      return [...[0, 0.25, 0.5, 0.75], ...ops]
    },
  },
  mounted() {
    this.displayQuantity = this.getDisplayQuantity()
    this.displayUnitId = this.value?.preferredDisplayUnitId
    this.displayLoaded = true
  },
  methods: {
    getDisplayQuantity() {
      let q = 0
      if (this.showUnits) {
        if (this.suffix === "cases") {
          q =
            this.value?.quantity /
            this.item.caseSize /
            this.item.packageMeasurement?.quantity
        } else if (this.suffix === "packages" || this.suffix === "pkgs") {
          q = this.value?.quantity / this.item.packageMeasurement?.quantity
        } else {
          q = this.convertBaseToDisplayQuantity(this.value?.quantity)
        }
      } else {
        q = this.value?.quantity
      }
      if (q < 1) {
        return Math.round(q * 4) / 4
      } else {
        return Math.round(q)
      }
    },
    handleChange() {
      if (this.showUnits) {
        if (this.suffix === "cases") {
          this.$emit("input", {
            unit: this.value?.unit,
            quantity:
              this.displayQuantity *
              this.item.caseSize *
              this.item.packageMeasurement?.quantity,
            preferredDisplayUnitId: this.displayUnitId,
          })
        } else if (this.suffix === "packages" || this.suffix === "pkgs") {
          this.$emit("input", {
            unit: this.value?.unit,
            quantity: this.displayQuantity * this.item.packageMeasurement?.quantity,
            preferredDisplayUnitId: this.displayUnitId,
          })
        } else {
          this.$emit("input", {
            unit: this.value?.unit,
            quantity: this.convertDisplayToBaseQuantity(this.displayQuantity),
            preferredDisplayUnitId: this.displayUnitId,
          })
        }
      } else {
        this.$emit("input", {
          unit: this.value?.unit,
          quantity: this.displayQuantity,
          preferredDisplayUnitId: this.displayUnitId,
        })
      }
    },
    convertDisplayToBaseQuantity(unit) {
      const itemUnit = this.units.find(u => u.id === this.displayUnitId)
      if (itemUnit?.base) {
        return unit * itemUnit.valInBase
      } else {
        return unit
      }
    },
    convertBaseToDisplayQuantity(unit) {
      const itemUnit = this.units.find(u => u.id === this.displayUnitId)
      if (itemUnit?.base) {
        return unit / itemUnit.valInBase
      } else {
        return unit
      }
    },
  },
}
</script>

<style scoped lang="scss">
.field-wrapper {
  border: 1px solid var(--v-lighttext-base);
  border-radius: 5px;
  &.scrollable {
    font-size: 1.25em;
    margin-top: 1em;
    margin-left: 10px;
  }
}
.scrollable-field {
  min-width: 3em;
  max-width: 3em;
  color: var(--v-text-base);
  height: 2em;
  margin: 10px;
  overflow: hidden;
  &.disabled {
    color: var(--v-lighttext-base);
  }
  > * {
    margin-top: -200%;
  }
}
.non-scrollable-field {
  min-width: 5em;
  max-width: 5em;
  margin-right: -1em;
  margin-top: -2px;
  color: var(--v-text-base);
  &.disabled {
    color: var(--v-lighttext-base);
  }
}
.unit-select {
  font-size: 1em;
}
</style>

<style lang="scss">
.field-wrapper .v-label {
  font-size: 1em !important;
}
.vue-scroll-picker-layer .middle {
  background: linear-gradient(
    0deg,
    var(--v-background-base) 0%,
    var(--v-background-base) 10%,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 0) 62%,
    var(--v-background-base) 90%,
    var(--v-background-base) 100%
  );
}
.vue-scroll-picker-layer .bottom {
  border: none;
}
</style>
