<template>
  <div
    v-intersect="{
      handler: handleIntersect,
    }"
  >
    <v-card-title>
      <h2>{{ item.name }}</h2>
    </v-card-title>
    <v-card-subtitle>
      <h3 class="mt-2 text--lighttext">
        {{ isCounting ? `ITEM ID: ${item.itemID}` : `SKU: ${item.supplierSKU}` }}
      </h3>
    </v-card-subtitle>
    <v-card-text>
      <v-container v-if="isCounting">
        <v-row class="pt-4">
          <v-col v-if="inputQuantities" class="pa-0">
            Counted Inventory:
            <v-row>
              <v-col>
                <div v-if="!item.isRecipe" class="d-flex">
                  <span class="pt-9" style="visibility: hidden">+</span>
                  <inventory-quantity-field
                    v-model="inputQuantities.cases"
                    scrollable
                    :loading="!viewed"
                    suffix="cases"
                    :item="item"
                    @input="inputTriggered"
                  />
                </div>
                <div
                  v-if="!item.isRecipe"
                  class="d-flex pt-1 px-6 mb-n2 text--lighttext justify-center"
                >
                  {{ getCaseMeasurementWithUnit(item) }} / case
                </div>
                <div class="d-flex">
                  <span class="pt-9" :style="item.isRecipe && 'visibility: hidden'">
                    +
                  </span>
                  <inventory-quantity-field
                    v-model="inputQuantities.units[0]"
                    scrollable
                    :loading="!viewed"
                    :unitsList="unitsList(item)"
                    :item="item"
                    @input="inputTriggered"
                  />
                </div>
              </v-col>
              <v-col>
                <div v-if="!item.isRecipe" class="d-flex">
                  <span class="pt-9">+</span>
                  <inventory-quantity-field
                    v-model="inputQuantities.packages"
                    scrollable
                    :loading="!viewed"
                    suffix="pkgs"
                    :item="item"
                    @input="inputTriggered"
                  />
                </div>
                <div
                  v-if="!item.isRecipe"
                  class="d-flex pt-1 px-6 mb-n2 text--lighttext justify-center"
                >
                  {{ getPackageMeasurementWithUnit(item) }} / package
                </div>
                <div
                  v-if="
                    inputQuantities.units[0] && inputQuantities.units[0].quantity > 0
                  "
                  class="d-flex"
                >
                  <span class="pt-9">+</span>
                  <inventory-quantity-field
                    v-model="inputQuantities.units[1]"
                    scrollable
                    :loading="!viewed"
                    :unitsList="unitsList(item)"
                    :item="item"
                    @input="inputTriggered"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>
        <v-row class="pt-8">
          <v-col class="pa-0">
            <h4 class="pa-4 text--lighttext text-center" style="min-height: 100px">
              Counted
              <br />
              {{ getMeasurementForDisplay(item.newMeasurement) }}
            </h4>
          </v-col>
          <v-col cols="1" class="pa-0">
            <h4 class="pa-4 mt-4 text--lighttext">&minus;</h4>
          </v-col>
          <v-col class="pa-0">
            <h4 class="pa-4 text--lighttext text-center" style="min-height: 100px">
              System
              <br />
              {{ getMeasurementForDisplay(item.measurement) }}
            </h4>
          </v-col>
          <v-col cols="1" class="pa-0">
            <h4 class="pa-4 mt-4 text--lighttext">&equals;</h4>
          </v-col>
          <v-col class="pa-0">
            <h4 class="pa-4 text--lighttext text-center" style="min-height: 100px">
              Variance
              <br />
              <span
                :style="{
                  color:
                    getItemMeasurementVariance() &&
                    getItemMeasurementVariance().quantity >= 0
                      ? 'green'
                      : 'red',
                }"
              >
                <span
                  v-if="
                    getItemMeasurementVariance() &&
                    getItemMeasurementVariance().quantity > 0
                  "
                >
                  +
                </span>
                {{ getMeasurementForDisplay(getItemMeasurementVariance()) }}
              </span>
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <custom-select
              v-model="item.storage"
              label="Storage"
              :items="storages"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details
              :disabled="!item.newMeasurement || !item.newMeasurement.quantity"
              @input="inputTriggered"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row class="pt-4">
          <v-switch
            v-model="receivable"
            color="success"
            class="mt-5"
            small
            inset
            readonly
            @click="receivable = !receivable"
          >
            <template v-slot:label>
              <div class="large-label">
                {{ receivable ? "Item is receivable" : "" }}
              </div>
            </template>
          </v-switch>
        </v-row>
        <template v-if="receivable">
          <v-row class="mt-5">
            <v-col class="position-relative text--lighttext">Packages Size:</v-col>
            <v-col>
              {{
                getQuantityInBaseUnits(
                  item.packageMeasurement?.quantity,
                  item.id
                ).toFixed(2)
              }}
              {{ getUnitName(item.packageMeasurement?.preferredDisplayUnitId) }} /
              package
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col class="position-relative text--lighttext">Packages Cost:</v-col>
            <v-col>
              <v-edit-dialog persistent large @save="updatePackageCost(item)">
                <span>
                  ${{
                    parseFloat(
                      getCostPerPackage(item) *
                        getQuantityInBaseUnits(
                          item.packageMeasurement?.quantity,
                          item.id
                        )
                    ).toFixed(2)
                  }}
                </span>
                <span v-if="item.costMoney">
                  {{ item.costMoney.currency }}
                </span>
                / package
                <v-icon small class="ml-2">mdi-pencil</v-icon>
                <template v-slot:input>
                  <div class="mt-4">
                    Enter New Package Cost (per
                    {{ getPackageCostPerUnitName(item) }})
                  </div>
                  <v-text-field
                    v-model="localAmount"
                    class="mt-2 shrink"
                    label="Edit"
                    single-line
                    type="number"
                    :messages="`Current cost: $${
                      item.costMoney &&
                      item.costMoney.amount * item.packageMeasurement?.quantity
                    }`"
                    outlined
                    dense
                  />
                </template>
              </v-edit-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-6 ml-3 align-center justify-center">
              <v-row>Packages Ordered:</v-row>
              <v-row class="d-flex align-center justify-center text--lighttext">
                <inventory-quantity-field
                  scrollable
                  :value="item.quantityOrdered"
                  :item="item"
                  disabled
                  @input="inputTriggered"
                />
              </v-row>
            </v-col>
            <v-col class="mt-6 align-center justify-center">
              <v-row>Packages Received:</v-row>
              <v-row class="d-flex align-center justify-center">
                <inventory-quantity-field
                  v-model="computedReceivingItem"
                  scrollable
                  :value="item.quantityOrdered"
                  :item="item"
                  disabled
                  @input="inputTriggered"
                />
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card-text>
  </div>
</template>

<script>
import InventoryQuantityField from "./InventoryQuantityField.vue"
import CustomSelect from "../../shared/CustomSelect.vue"
import { mapGetters, mapState } from "vuex"
import topLevelMixins from "../../mixins.js"

export default {
  name: "item-card",
  components: {
    InventoryQuantityField,
    CustomSelect,
  },
  mixins: [topLevelMixins],
  props: {
    isCounting: {
      type: Boolean,
      required: true,
      default: true,
    },
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewed: false,
      inputQuantities: null,
      receivable: true,
      localAmount: null,
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState(["units", "storages", "items"]),
    computedReceivingItem: {
      get() {
        return {
          unit: this.item.packageMeasurement?.unit,
          quantity: this.inputQuantities,
          preferredDisplayUnitId:
            this.item.packageMeasurement?.preferredDisplayUnitId,
        }
      },
      set(newValue) {
        this.inputQuantities = newValue.quantity
      },
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.inputQuantities = newVal
      },
      deep: true,
    },
    inputQuantities: {
      handler(newVal) {
        this.inputQuantities = newVal
      },
      deep: true,
    },
  },
  async mounted() {
    this.viewed = this.$el?.getBoundingClientRect()?.y < 300
    this.inputQuantities = this.value
    this.receivable = Boolean(this.item.quantityOrdered)
  },
  methods: {
    inputTriggered() {
      this.$emit("input", this.inputQuantities)
    },
    handleIntersect(entries) {
      this.viewed = entries[0].isIntersecting
    },
    getItemMeasurementVariance() {
      return {
        unit: this.item.measurement?.unit,
        preferredDisplayUnitId: this.item.measurement?.preferredDisplayUnitId,
        quantity:
          (this.item.newMeasurement?.quantity || 0) -
          this.item.measurement?.quantity,
      }
    },
    updatePackageCost(item) {
      if (!item.packageMeasurement) return 0
      item.costMoney.prevAmount = item.costMoney.amount
      item.costMoney.amount = this.localAmount / item.packageMeasurement?.quantity
      this.$emit("input", item)
    },
    getPackageCostPerUnitName(item) {
      const quantity = this.getQuantityInBaseUnits(
        item.packageMeasurement?.quantity,
        item.id
      )
      const unitName = this.getUnitName(
        item.packageMeasurement?.preferredDisplayUnitId,
        item.packageMeasurement?.quantity > 1
      )
      return `${quantity} ${unitName}`
    },
    unitsList(item) {
      return this.associatedInventoryItem(item)
        ? this.units.filter(u => u.base === this.thisItemUnit(item)?.base)
        : this.units
    },
    associatedInventoryItem(item) {
      return item.linkedItemID
        ? this.items.find(iItem => iItem.itemID === item.linkedItemID)
        : null
    },
    thisItemUnit(item) {
      return this.units.find(
        u =>
          u.id ===
          this.associatedInventoryItem(item).measurement?.preferredDisplayUnitId
      )
    },
  },
}
</script>

<style scoped>
.position-relative {
  position: relative;
}
.large-label {
  font-size: 2em !important;
}
</style>
