<template>
  <div>
    <page-component
      :listType="(isTouchDevice && 'cards') || 'table'"
      pageTitle="Count New Inventory"
      :headers="headers"
      :items="filteredItems"
      :loading="loading"
      enableCardView
      hidefilterToggle
    >
      <template v-slot:card="{ item }">
        <item-card
          v-if="item.inputQuantities"
          v-model="item.inputQuantities"
          :item="item"
          isCounting
          @input="inputQuantitiesChanged(item)"
        />
      </template>
      <template v-slot:filters[0]>
        <custom-date-picker
          v-model="date"
          :label="date ? 'Date' : 'Select Date'"
          dense
          init
          showTime
        />
      </template>
      <template v-slot:filters[1]>
        <custom-select
          v-model="selectedStorage"
          :items="[{ id: '', name: 'All Storages' }, ...storages]"
          item-text="name"
          item-value="id"
          label="Storage"
          dense
        />
      </template>
      <template v-slot:filters[2]>
        <custom-select
          v-model="selectedSubcategory"
          :items="[{ id: '', name: 'All Subcategories' }, ...subcategories]"
          item-text="name"
          item-value="id"
          label="Subcategory"
          dense
        />
      </template>
      <template v-slot:[`item.caseSize`]="props">
        <span v-if="props.item.caseSize">
          {{ props.item.caseSize }} pkg{{ props.item.caseSize != 1 ? "s" : "" }}
          <span v-if="props.item.packageMeasurement" class="text--lighttext">
            <br />
            {{ getCaseMeasurementWithUnit(props.item) }}
          </span>
        </span>
      </template>
      <template v-slot:[`item.packageSize`]="props">
        <span v-if="props.item.packageMeasurement && props.item.linkedItemID">
          {{ getPackageMeasurementWithUnit(props.item) }}
        </span>
      </template>
      <template v-slot:[`item.measurement`]="props">
        <span v-if="props.item.measurement">
          {{ getMeasurementForDisplay(props.item.measurement) }}
        </span>
      </template>
      <template v-slot:[`item.quantity`]="props">
        <v-row v-if="props.item.inputQuantities" class="my-2 mx-0">
          <v-col class="pa-0" cols="6">
            <inventory-quantity-field
              v-model="props.item.inputQuantities.cases"
              :item="props.item"
              suffix="cases"
              @input="inputQuantitiesChanged(props.item)"
            />
          </v-col>
          <v-col class="pa-0 pl-2" cols="6">
            <inventory-quantity-field
              v-model="props.item.inputQuantities.packages"
              :item="props.item"
              suffix="pkgs"
              @input="inputQuantitiesChanged(props.item)"
            />
          </v-col>
        </v-row>
        <v-row v-if="props.item.inputQuantities" class="my-2 mx-0">
          <v-col class="pa-0" cols="6">
            <inventory-quantity-field
              v-model="props.item.inputQuantities.units[0]"
              :item="props.item"
              suffix="units"
              :unitsList="unitsList(props.item)"
              @input="inputQuantitiesChanged(props.item)"
            />
          </v-col>
          <v-col class="pa-0 pl-2" cols="6">
            <inventory-quantity-field
              v-if="
                props.item.inputQuantities.units[0] &&
                props.item.inputQuantities.units[0].quantity
              "
              v-model="props.item.inputQuantities.units[1]"
              :item="props.item"
              suffix="units"
              :unitsList="unitsList(props.item)"
              @input="inputQuantitiesChanged(props.item)"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.newMeasurement`]="props">
        <span v-if="props.item.measurement">
          {{ getMeasurementForDisplay(props.item.newMeasurement) }}
        </span>
      </template>
      <template v-slot:[`item.variance`]="props">
        <span
          :style="{
            color:
              getItemMeasurementVariance(props.item).quantity >= 0 ? 'green' : 'red',
          }"
        >
          <span v-if="getItemMeasurementVariance(props.item).quantity > 0">+</span>
          {{ getMeasurementForDisplay(getItemMeasurementVariance(props.item)) }}
        </span>
      </template>
      <template v-slot:[`item.storage`]="props">
        <custom-select
          v-model="props.item.storage"
          label=""
          :items="storages"
          item-text="name"
          item-value="id"
          dense
          outlined
          hide-details
          :disabled="
            !props.item.newMeasurement || !props.item.newMeasurement.quantity
          "
          @input="updateQuantity()"
        />
      </template>
    </page-component>
    <v-footer fixed elevation="3" class="fixed-btn-container py-4">
      <v-btn
        :disabled="countedItems.length === 0"
        color="primary"
        class="fixed-btn"
        :to="{ params: { form: 'Review' } }"
      >
        Review
      </v-btn>
    </v-footer>
    <review :date="date" :countItems="countedItems" @success="closeReview" />
  </div>
</template>

<script>
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import Review from "./Review"
import ItemCard from "@/AuthenticatedContent/shared/forms/ItemCard.vue"
import CustomDatePicker from "@/AuthenticatedContent/shared/forms/datetime/CustomDatePicker.vue"
import InventoryQuantityField from "@/AuthenticatedContent/shared/forms/InventoryQuantityField.vue"
import CustomSelect from "@/AuthenticatedContent/shared/CustomSelect.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import { mapState, mapGetters } from "vuex"
import moment from "moment"

export default {
  name: "count-new",
  components: {
    PageComponent,
    ItemCard,
    Review,
    CustomDatePicker,
    InventoryQuantityField,
    CustomSelect,
  },
  mixins: [topLevelMixins],
  data() {
    return {
      loading: false,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      selectedSubcategory: "",
      selectedStorage: "",
      headers: [
        { text: "Item ID", value: "itemID" },
        { text: "Name", value: "name" },
        { text: "Case Size", value: "caseSize" },
        { text: "Package Size", value: "packageSize" },
        { text: "System Quantity", value: "measurement" },
        { text: "Counted Total", value: "newMeasurement" },
        { text: "Count Variance", value: "variance" },
        { text: "Counted Quantity", value: "quantity", width: 300 },
        { text: "Storage", value: "storage" },
      ],
      itemsForCount: [],
      countedItems: [],
    }
  },
  computed: {
    ...mapGetters(["getUnitName", "isTouchDevice"]),
    ...mapState(["subcategories", "supplierItems", "storages", "items", "units"]),
    filteredItems() {
      return this.itemsForCount.filter(
        item =>
          (!this.selectedSubcategory ||
            item?.subcategory == this.selectedSubcategory) &&
          (!this.selectedStorage || item?.storage == this.selectedStorage)
      )
    },
  },
  async created() {
    this.loading = true
    this.itemsForCount = this.items.map(item => {
      const supplierItem = this.supplierItems.find(
        sItem => sItem.linkedItemID === item.itemID
      )
      return { ...item, ...supplierItem }
    })
    this.initializeQuantityUnits()
    await this.updateQuantity()
    this.loading = false
  },
  methods: {
    unitsList(item) {
      return this.associatedInventoryItem(item)
        ? this.units.filter(u => u.base === this.thisItemUnit(item)?.base)
        : this.units
    },
    associatedInventoryItem(item) {
      return item.linkedItemID
        ? this.items.find(iItem => iItem.itemID === item.linkedItemID)
        : null
    },
    thisItemUnit(item) {
      return this.units.find(
        u =>
          u.id ===
          this.associatedInventoryItem(item).measurement?.preferredDisplayUnitId
      )
    },
    getItemMeasurementVariance(item) {
      return {
        unit: item.measurement?.unit,
        preferredDisplayUnitId: item.measurement?.preferredDisplayUnitId,
        quantity: (item.newMeasurement?.quantity || 0) - item.measurement?.quantity,
      }
    },
    initializeQuantityUnits() {
      this.itemsForCount.forEach(item => {
        if (!item.inputQuantities) {
          this.$set(item, "inputQuantities", {
            cases: {
              quantity: 0,
              unit: item.measurement?.unit,
              preferredDisplayUnitId: item.measurement?.preferredDisplayUnitId,
            },
            packages: {
              quantity: 0,
              unit: item.measurement?.unit,
              preferredDisplayUnitId: item.measurement?.preferredDisplayUnitId,
            },
            units: [
              {
                quantity: 0,
                preferredDisplayUnitId: item.measurement?.preferredDisplayUnitId,
                unit: item.measurement?.unit,
              },
              {
                quantity: 0,
                preferredDisplayUnitId: "",
                unit: item.measurement?.unit,
              },
            ],
          })
        }
      })
    },
    inputQuantitiesChanged(item) {
      this.$set(item, "newMeasurement", {
        unit: item.measurement.unit,
        preferredDisplayUnitId: item.measurement.preferredDisplayUnitId,
        quantity:
          item.inputQuantities.cases.quantity +
          item.inputQuantities.packages.quantity +
          item.inputQuantities.units[0].quantity +
          item.inputQuantities.units[1].quantity,
      })
      this.updateQuantity()
    },
    updateQuantity() {
      this.countedItems = JSON.parse(JSON.stringify(this.itemsForCount)).filter(
        sItem => sItem.newMeasurement?.quantity > 0
      )
    },
    closeReview() {
      this.$router.push({ params: { tab: "history", form: null, id: null } })
    },
  },
}
</script>
